<template>
  <div>
    <div class ="row justify-content-center mt-2">
      <div class="col-md-6">
        <div class="card login-form" style="height: 80px !important">
          <div class="card-body">
            <a class="btn btn-primary btn-lg btn-block" :href="returnUrl">Nothi Login</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data: () => ({
    returnUrl: '',
    errorMsg: ''
  }),
  created () {
    // this.returnUrl = 'https://n-doptor-accounts-stage.nothi.gov.bd/login?referer=' + btoa('http://142.234.200.106:8080/nothi-login')
    this.returnUrl = 'https://n-doptor-accounts-stage.nothi.gov.bd/login?referer=' + btoa(window.location.origin + '/auth/nothi-login-process')
  }
}
</script>

<style scoped>
  .login-form {
    height: 100vh;
  }
  .card {
        background-color: transparent !important;
        background: #1d4c1d56 !important;
        border: none !important;
        color: #fff !important;
        min-width: 24em !important;
  }
  .card-header{background-color: #c7cef8 !important; border-bottom: 0px!important;}
  .btn-custom{background-color: #596DE1; color: #fff;}
  a {
    color: #ffffff !important;
  }
  a:hover {
      color: #eee !important;
      text-decoration: underline !important;
  }
  .form-control {
    background: #FFF !important;
    color: #000 !important;
    /* color: #ffffff !important; */
  }
  input[data-v-2e90fba7] {
      border: 1px solid #c1c1c1;
      height: 32px !important;
  }
</style>
